import React from 'react';
import { useSelector } from 'react-redux';
import { smilingFace } from '../../../../shared/assets/emojis';

import PurchaseTypeSelect from './components/inputs/PurchaseTypeSelect';
import SituationSelect from './components/inputs/SituationSelect';
import ProjectStateSelect from './components/inputs/ProjectStateSelect';

import { Emoji, InfoIcon } from 'hosman-material';
import { LoadingLayer, TextInput, RadioInput, PhoneNumberInput } from 'hosman-material';

const ContactDetailsForm = ({ buyerAlert, setStateBuyerAlert, errors, buyerAlertUser, setStateBuyerAlertUser, isDisabled }) => {
  return (
    <div className='flex flex-col items-center gap-12'>
      <div className='flex justify-center p-3 bg-ih-purple-5 w-full'>
        <p className='h4 text-ih-blue'>
          Encore quelques informations pour <span className='text-ih-blue'>finaliser votre alerte achat !</span>
        </p>

        <Emoji emojiSrc={smilingFace} width='w-5' />
      </div>

      <div className='flex flex-col gap-8 text-sm w-full'>
        <div className='flex flex-col md:flex-row gap-5'>
          <PurchaseTypeSelect value={buyerAlert.purchase_type } onChange={(e) => setStateBuyerAlert({ ...buyerAlert, purchase_type: e.value })} errors={errors} />

          <SituationSelect value={buyerAlertUser.situation} onChange={(e) => setStateBuyerAlertUser({ ...buyerAlertUser, situation: e.value })} errors={errors} />
        </div>

        <ProjectStateSelect value={buyerAlertUser.project_state} onChange={(e) => setStateBuyerAlertUser({ ...buyerAlertUser, project_state: e.value })} errors={errors} />

        <RadioInput
          label='Titre de civilité :'
          name='civility'
          options={[
            { name: 'Monsieur', value: 'mr' },
            { name: 'Madame', value: 'mrs' }
          ]}
          value={buyerAlertUser.civility}
          onChange={(val) => setStateBuyerAlertUser({ ...buyerAlertUser, civility: val })}
          errors={errors}
        />

        <div className='flex flex-col md:flex-row gap-5'>
          <TextInput label='Prénom'
                     name='first_name'
                     value={buyerAlertUser.first_name}
                     onChange={(e) => setStateBuyerAlertUser({ ...buyerAlertUser, first_name: e.target.value })}
                     errors={errors}
                     disabled={isDisabled}
                     autoComplete='given-name' />

          <TextInput label='Nom'
                     name='last_name'
                     value={buyerAlertUser.last_name}
                     onChange={(e) => setStateBuyerAlertUser({ ...buyerAlertUser, last_name: e.target.value })}
                     errors={errors}
                     disabled={isDisabled}
                     autoComplete='family-name' />
        </div>

        <div className='flex flex-col md:flex-row gap-5'>
          <div className='flex-1'>
            <TextInput label='E-mail'
                       name='email'
                       value={buyerAlertUser.email}
                       onChange={(e) => setStateBuyerAlertUser({ ...buyerAlertUser, email: e.target.value })}
                       errors={errors}
                       disabled={isDisabled}
                       autoComplete='email' />
          </div>

          <div className='flex-2'>
            <PhoneNumberInput
              onChangePhone={(val) => setStateBuyerAlertUser({ ...buyerAlertUser, phone_number: val })}
              onChangeCountryCode={(val) => setStateBuyerAlertUser({ ...buyerAlertUser, phone_country_code: val })}
              phoneValue={buyerAlertUser.phone_number}
              countryCodeValue={buyerAlertUser.phone_country_code}
              errors={errors}
              disabled={isDisabled}
              autoComplete='tel-national'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactDetailsForm;
