import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { S_CalendarDays, D_FileLines } from 'hosman-material';

import { Card, Button } from 'hosman-material';

export const NoVisitReportCard = ({ saleId }) => {
  return (
    <div className='md:w-96 md:mt-20 mx-auto' data-testid='NoVisitReportCard'>
      <Card>
        <div className='flex flex-col gap-5 px-5 py-8 text-center'>
          <FontAwesomeIcon icon={D_FileLines} size='4x' className='text-ih-blue' />
          <p className='h3 text-ih-indigo'>Pas de compte rendu</p>
          <p className='text-ih-indigo'>Il n'y a pas encore de compte rendu de visites disponible.</p>
          <Link to={`/ev/ventes/${saleId}/planning`}>
            <Button label='Voir le planning de visite' small icon={S_CalendarDays} />
          </Link>
        </div>
      </Card>
    </div>
  );
};

// PropTypes
NoVisitReportCard.propTypes = {
  saleId: PropTypes.string.isRequired
};
