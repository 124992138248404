import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { generateLink } from '../../../../../../../shared/utils/railsRoutes';

import NavigationButtons from '../../components/NavigationButtons'
import { FadeIn, RadioInput, TextInput, PhoneNumberInput } from 'hosman-material';

import { updateStep, updateEstimationData, updateErrors } from '../../../../../../reducers/_Pages/Public/estimationFunnelSlice';

const Contact = () => {
  const { currentStep, estimationData, estimationData: { lead }, errors} = useSelector((state) => state.estimationFunnel);
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user.id) {
      dispatch(updateEstimationData({
        ...estimationData,
        lead: {
          civility: user.civility,
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
          phone_number: user.phone_number,
          phone_country_code: user.phone_country_code,
        }
      }))

      dispatch(updateStep({ stepNumber: currentStep.number + 1 }))
    }
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target
    dispatch(updateEstimationData({
      ...estimationData,
      lead: {
        ...lead,
        [name]: value
      }
    }))

    removeFieldError(name)
  };

  const removeFieldError = (name) => {
    if (!errors[name]) return;

    const newErrors = {...errors}
    delete newErrors[name];
    dispatch(updateErrors(newErrors))
  }

  return (
    <div data-testid='Contact'>
      <FadeIn>
        <div className='flex flex-col gap-8'>
          <div className='flex flex-col gap-1 text-center'>
            <p className='h2 text-ih-indigo'>Votre estimation <span className='text-ih-blue'>est prête !</span></p>
            <p className='text-sm text-ih-indigo-60'>Renseignez ces informations afin de recevoir votre estimation.</p>
          </div>

          <div className='flex flex-col gap-4'>
            <RadioInput
              name='civility'
              options={[
                { name: 'Monsieur', value: 'mr' },
                { name: 'Madame', value: 'mrs' }
              ]}
              value={lead.civility}
              onChange={(val) => handleInputChange({ target: { name: 'civility', value: val }})}
              small
              errors={errors}
            />

            <TextInput label='Prénom' name='first_name' placeholder='Caroline' value={lead.first_name} onChange={handleInputChange} errors={errors} autoComplete='given-name' />

            <TextInput label='Nom' name='last_name' placeholder='Gomez' value={lead.last_name} onChange={handleInputChange} errors={errors} autoComplete='family-name' />

            <TextInput label='Adresse mail' name='email' placeholder='carolinegomez@gmail.com' value={lead.email} onChange={handleInputChange} errors={errors} autoComplete='email' />

            <PhoneNumberInput
              name='phone_number'
              placeholder='07 89 78 65 89'
              onChangePhone={(val) => handleInputChange({ target: { name: 'phone_number', value: val }})}
              onChangeCountryCode={(val) => handleInputChange({ target: { name: 'phone_country_code', value: val }})}
              phoneValue={lead.phone_number}
              countryCodeValue={lead.phone_country_code}
              errors={errors}
              autoComplete='tel-national'
            />

            <NavigationButtons classes='hidden md:block my-5' />

            <p className='md:w-3/2 mx-auto text-center text-sm text-ih-indigo-50'>En confirmant, vous acceptez notre <a href={generateLink(RailsRoutes.personal_data_path())} target='_blank' className='font-semibold underline'>politique d'utilisation des données personnelles.</a></p>
          </div>
        </div>
      </FadeIn>
    </div>
  )
}

export default Contact;
